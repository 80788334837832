.otp-type-select {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px 0;
    position: relative;
    user-select: none;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 16px;
      height: 16px;
      margin: -8px 0 0 0;
      border: 2px solid #ffffff;
      border-radius: 100%;
    }

    &.selected::after {
      border: 0;
      border-radius: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../../assets/check-circle.svg);
    }
  }

  p {
    margin: 0;
    padding: 0;

    &.title {
      font-weight: 500;
    }

    &.info {
      color: #aaaaaa;
    }
  }
}
