@import '../../styles/base.scss';

.tab-wrap {
  position: relative;

  .slick-dots {
    top: -50px;
    position: absolute;
    bottom: unset;
    background-color: rgba(255, 255, 255, 0.1);
    height: 54px;
    border-radius: 44px;
    display: flex !important;
    align-items: center;
    justify-content: stretch;

    li {
      position: relative;
      height: 54px;
      width: calc(100% / 2);
      margin: 0;
      padding: 0;
      text-align: center;

      .tab-nav {
        height: 54px;
        position: relative;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.slick-active::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: #4b4b4b;
        width: 100%;
        height: 54px;
        border-radius: 44px;
      }
    }
  }

  .slick-slider {
    position: unset;
  }

  .slick-list {
    margin-top: 80px;
  }

  .tab-saved-accounts,
  .tab-new-accounts {
    padding: 0 5px;
  }
}

.amount-field {
  background-color: transparent;
  display: flex;
  gap: 2px;
  border: 1px solid $input-border-color;
  padding: 0 15px;
  border-radius: 6px;
  margin-bottom: 3px;

  span {
    color: $color-links-default;
    font-weight: 500;
    margin: auto;
    cursor: pointer;
  }
}

.account-details-container {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 9999;
    border: 1px solid rgba($color-secondary, 0);
    border-radius: 8px;
    padding: 14px 15px;
    transition: border-color 0.3s ease;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 10px;

    &.selected {
      border-color: rgba($color-secondary, 1);
    }

    .wrap {
      display: flex;
    }

    .inner {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .account-details {
      display: flex;
      gap: 6px;
    }

    .acct-num {
      font-size: $font-size-default;
      font-weight: $font-weight-500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;

      @media screen and (max-width: 767px) {
        max-width: 100px;
      }
    }

    .text-white {
      color: #ffffff;
    }

    .text-gray {
      color: #6c6c6c;
    }

    .font-small {
      font-size: $font-size-medium;
      font-weight: 400;
      color: #6c6c6c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;

      @media screen and (max-width: 767px) {
        max-width: 80px;
      }
    }

    .separator {
      display: inline-block;
      width: 3px;
      height: 3px;
      margin: 0 5px;
      border-radius: 100%;
      background-color: #d9d9d9;
      font-weight: $font-weight-500;
      margin: auto;
    }

    .right {
      cursor: pointer;
    }

    .left img {
      background-color: #4b4b4b;
      padding: 5px;
      border-radius: 100%;
    }
  }
}

.bank-name {
  margin-top: 5px;
}

.note {
  p {
    font-size: $font-size-default;
    font-weight: 400;
    text-align: center;
  }
}

.balance-container {
  background-color: #4b4b4b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 10px;
  margin: 0 15px;
}

.confirm-wrapper {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  padding: 0 16px;

  .confirm-item {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    border-bottom: 1px solid #ffffff28;

    .lhs {
      font-size: $font-size-medium;
      color: #aaaaaa;
      font-weight: 400;
    }
    .rhs {
      font-size: $font-size-medium;
      font-weight: 400;
    }
  }
  .confirm-item-last {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;

    .lhs {
      font-size: $font-size-medium;
      color: #aaaaaa;
      font-weight: 400;
    }
    .rhs {
      font-size: $font-size-medium;
      font-weight: 400;
    }
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;

  button {
    max-width: 80vw;
    margin: 0 auto;
  }

  + .app-inner {
    padding-bottom: 0;
  }
}
