$primary-font-family: 'Manrope', 'Segoe UI', sans-serif;

$font-size-default: 16px;
$font-size-medium: 14px;
$font-size-small: 12px;
$font-style-default: normal;

$btn-font-size-default: 16px;

$font-family-headings: 'Manrope', sans-serif;

$font-weight-default: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

.font-weight-500 {
  font-weight: $font-weight-500 !important;
}
.font-weight-600 {
  font-weight: $font-weight-600 !important;
}
