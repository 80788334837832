@charset "UTF-8";

@import './variables';
@import './typography';
@import './include-media';
@import './colors';

@-ms-viewport {
  width: device-width;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  background: $color-white;
  font-size: $font-size-default;
  color: $color-text-default;
  font-weight: $font-weight-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  font-family: $primary-font-family;
  font-style: $font-style-default;

  iframe {
    display: none !important;
  }
}

#root {
  position: relative;
}

a {
  color: $color-links-default !important;
  text-decoration: none;
  font-weight: 500;
}
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}
a:hover {
  color: $color-links-default--hover;
  text-decoration: none;
}

p {
  font-family: $primary-font-family;
  font-size: $font-size-default;
  font-weight: 400;
}

.h-100p {
  height: 100% !important;
}

.max-w-250x {
  max-width: 250px !important;
}
.max-w-300x {
  max-width: 300px !important;
}
.max-w-320x {
  max-width: 320px !important;
}
.max-w-350x {
  max-width: 350px !important;
}
.max-w-500x {
  max-width: 500px !important;
}
.max-w-400x {
  max-width: 400px !important;
}
.max-w-450x {
  max-width: 450px !important;
}
.max-w-600x {
  max-width: 600px !important;
}
.max-w-700x {
  max-width: 700px !important;
}
.max-w-800x {
  max-width: 800px !important;
}

.margin-none {
  margin: 0 !important;
}
.margin-auto_center {
  margin: 0 auto !important;
}

.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.no-scroll {
  overflow: hidden !important;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.hidden {
  display: none !important;
}
.invisible {
  opacity: 0 !important;
  filter: opacity(0);
}

.centered {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.centered:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.centered .centered-content {
  vertical-align: middle;
  max-width: 98%;
  display: inline-block;
  text-align: left !important;
}

[data-vertical_center],
[data-vertical_center='true'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

[data-vertical_end],
[data-vertical_end='true'] {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.full-width {
  width: 100% !important;
}
.full-height {
  height: 100% !important;
}
.divider-right--full-width {
  border-right: 1px solid #e2e1e1;
}

.Toastify__toast-container {
  width: auto;
}
.Toastify__toast-container--bottom-center {
  left: 50% !important;
}
.Toastify__toast-container--top-center {
  left: 50% !important;
}

.custom-toast-container {
  height: 3px;
  border-radius: 36px;
  padding: 0 36px;
  min-width: 180px;
  text-align: center;

  &.Toastify__toast--dark {
    background: #4b4b4b;
  }
}

@media (max-width: 576px) {
  .full-height-sm-none {
    height: auto !important;
  }
  .full-width-sm-none {
    width: auto !important;
  }
  .overflow-y-sm-none {
    overflow-y: unset !important;
  }
  .table-responsive-sm {
    white-space: nowrap;
  }
}

.cursor-auto {
  cursor: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-none {
  cursor: none;
}
.cursor-context-menu {
  cursor: context-menu;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-progress {
  cursor: progress;
}
.cursor-wait {
  cursor: wait;
}
.cursor-cell {
  cursor: cell;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-text {
  cursor: text;
}
.cursor-vertical-text {
  cursor: vertical-text;
}
.cursor-alias {
  cursor: alias;
}
.cursor-copy {
  cursor: copy;
}
.cursor-move {
  cursor: move;
}
.cursor-no-drop {
  cursor: no-drop;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-all-scroll {
  cursor: all-scroll;
}
.cursor-col-resize {
  cursor: col-resize;
}
.cursor-row-resize {
  cursor: row-resize;
}
.cursor-n-resize {
  cursor: n-resize;
}
.cursor-e-resize {
  cursor: e-resize;
}
.cursor-s-resize {
  cursor: s-resize;
}
.cursor-w-resize {
  cursor: w-resize;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-ew-resize {
  cursor: ew-resize;
}
.cursor-ne-resize {
  cursor: ne-resize;
}
.cursor-nw-resize {
  cursor: nw-resize;
}
.cursor-se-resize {
  cursor: se-resize;
}
.cursor-sw-resize {
  cursor: sw-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}

.vgap--1x {
  height: 0.1rem;
}
.vgap--2x {
  height: 0.2rem;
}
.vgap--3x {
  height: 0.3rem;
}
.vgap--4x {
  height: 0.4rem;
}
.vgap--5x {
  height: 0.5rem;
}
.vgap-1x {
  height: 1rem;
}
.vgap-2x {
  height: 2rem;
}
.vgap-3x {
  height: 3rem;
}
.vgap-4x {
  height: 4rem;
}
.vgap-5x {
  height: 5rem;
}

.hgap--1x {
  display: inline-block;
  vertical-align: middle;
  width: 0.1rem;
}
.hgap--2x {
  display: inline-block;
  vertical-align: middle;
  width: 0.2rem;
}
.hgap--3x {
  display: inline-block;
  vertical-align: middle;
  width: 0.3rem;
}
.hgap--4x {
  display: inline-block;
  vertical-align: middle;
  width: 0.4rem;
}
.hgap--5x {
  display: inline-block;
  vertical-align: middle;
  width: 0.5rem;
}
.hgap-1x {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
}
.hgap-2x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-3x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-4x {
  display: inline-block;
  vertical-align: middle;
  width: 4rem;
}
.hgap-5x {
  display: inline-block;
  vertical-align: middle;
  width: 5rem;
}

.pt--1x {
  padding-top: 0.1rem;
}
.pt--2x {
  padding-top: 0.2rem;
}
.pt--3x {
  padding-top: 0.3rem;
}
.pt--4x {
  padding-top: 0.4rem;
}
.pt--5x {
  padding-top: 0.5rem;
}
.pt-1x {
  padding-top: 1rem;
}
.pt-2x {
  padding-top: 2rem;
}
.pt-3x {
  padding-top: 2rem;
}
.pt-4x {
  padding-top: 4rem;
}
.pt-5x {
  padding-top: 5rem;
}

.px-25x {
  padding-left: 25px;
  padding-right: 25px;
}

::-moz-placeholder {
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}
::-webkit-input-placeholder {
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
  transition: background-color 5000s ease-in-out 0s;
}

//removes spinners from number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
}

.text-muted {
  color: $text-muted-color !important;
}

.btn {
  padding: 0;
  height: 54px;
  display: flex;
  width: 100%;
  border-radius: 54px;
  align-items: center;
  justify-content: center;
  font-size: $btn-font-size-default !important;
  box-shadow: none !important;
  font-weight: $font-weight-500;
  padding-top: 0.3rem !important;

  &.btn-width-150px {
    width: 150px;
  }
  &.btn-width-100px {
    width: 100px;
  }
}
a.btn {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  opacity: 1 !important;
}
.btn-primary {
  background-color: $btn-primary-background-color !important;
  border-color: $btn-primary-border-color !important;

  &:hover,
  &::after,
  &:active {
    background-color: $btn-primary-background-color !important;
    border-color: $btn-primary-border-color !important;
  }

  &.btn-outline {
    background-color: transparent !important;
    color: $color-text-primary;
  }

  &.light {
    border-width: 0;
    color: #6932d4 !important;
    background: rgba(197, 178, 240, 0.5) !important;
    border-color: rgba(197, 178, 240, 0.5) !important;
  }
  &.dark {
    border-width: 0;
    color: #ffffff !important;
    background: #373639 !important;
    border-color: #373639 !important;
  }
}
.btn-google-login {
  background-color: $color-white;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  padding-top: 0 !important;
  font-weight: $font-weight-500;
}

.form-group {
  label {
    color: $text-muted-color;
    font-size: $font-size-medium;
  }
}

input.form-control,
textarea.form-control,
select.form-control {
  height: 54px;
  color: $color-white !important;
  border: 1px solid $input-border-color !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  background-color: $input-background-color !important;
}
input.form-control:focus,
textarea.form-control:focus,
input.form-control:active,
textarea.form-control:active {
  color: $color-white !important;
  box-shadow: none !important;
  border: solid 1px $input-border-color--active !important;
  background-color: $input-background-color !important;
}

input.form-control-two,
textarea.form-control-two,
select.form-control-two {
  width: 100%;
  height: 54px;
  color: $color-white !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  background-color: transparent !important;
}
input.form-control-two:focus,
textarea.form-control-two:focus,
input.form-control-two:active,
textarea.form-control-two:active {
  color: $color-white !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  outline: none;
}

.sel-custom {
  position: relative;
  cursor: pointer;

  select {
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 14px;
    height: 14px;
    margin: -7px 0 0 0;
    background-image: url(../assets/chevron-down.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
.form-group.select_input {
  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sel-custom::after {
    width: 10px;
    height: 10px;
    margin: -5px 0 0 0;
  }

  select:first-child {
    min-width: 100px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
  }

  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
  }

  .separator {
    height: 25px;
    width: 1px;
    background-color: #b8bbd4;
  }
}

::-moz-placeholder {
  color: $color-white;
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}
::-webkit-input-placeholder {
  color: $color-white;
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: $color-white !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: $color-white;
}
input:-webkit-autofill:focus {
  -webkit-text-fill-color: $color-white;
}

//removes spinners from number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.otp-input-container {
  width: 100%;
}
.otp-input-container div {
  display: block !important;
  flex: 1;
  margin: 5px;
}
.otp-input-container div input {
  width: 100% !important;
  height: 50px;
}

.app-container {
  position: relative;
  background-color: $default-background-color;
  padding: 25px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -55px;
    left: -95px;
    width: 100%;
    max-width: 35%;
    height: 90%;
    transform: rotate(-5deg);
    opacity: 0.1;
    filter: blur(100px);
    background: linear-gradient(141.55deg, #e478ff -3.79%, #f20089 82.82%);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -100px;
    right: 0;
    width: 100%;
    max-width: 35%;
    height: 50%;
    transform: rotate(-5deg);
    opacity: 0.1;
    filter: blur(100px);
    background: linear-gradient(141.55deg, #e478ff -3.79%, #f20089 82.82%);
  }

  .app-wrap {
    position: relative;
    z-index: 999;
    min-height: 100vh;
    border-radius: 18px;
    background-color: $default-background-color;
    max-width: 500px;
    margin: 0 auto;
  }

  .app-head {
    position: relative;
    text-align: center;
    padding-top: 35px;

    img {
      margin-bottom: 15px;
    }

    .back {
      cursor: pointer;
      position: absolute;
      top: 35px;
      left: 0;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../assets/arrow-left.svg);
    }

    .info {
      cursor: pointer;
      position: absolute;
      top: 35px;
      right: 0;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../assets/information-circle.svg);
    }

    &.app-head-default {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;

      img {
        margin: 0;
      }

      .options-wrap {
        position: relative;
        z-index: 9999;
      }

      .options {
        display: block;
        text-align: center;
        width: 30px;
        cursor: pointer;
        font-size: 1.1em;
        color: $color-white !important;
      }
    }
  }

  .app-inner {
    padding: 0 25px 50px;
  }

  @media (max-width: 768px) {
    background-color: $default-background-color;
    padding: 0;

    .app-wrap {
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.rate-info-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .rate-info {
    display: inline-block;
    padding: 0 20px;
    height: 38px;
    line-height: 38px;
    margin: 0 auto;
    border-radius: 38px;
    background-color: $color-primary;
    font-weight: $font-weight-600;

    .separator {
      position: relative;
      margin: 0 10px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        margin: -2.5px 0 0 -2.5px;
        border-radius: 100%;
        background-color: $color-secondary;
      }
    }

    @media (max-width: 500px) {
      font-size: $font-size-medium;
      padding: 0 15px;
    }

    @media (max-width: 450px) {
      height: 30px;
      line-height: 30px;
      font-size: $font-size-small;
      padding: 0 15px;
    }
  }
}

.footer {
  ul.links {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    list-style: none;
    margin: 0;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      display: block;
      cursor: pointer;
      padding: 20px 0;
      color: #d4d4d4;
    }
  }

  .logo-area {
    text-align: center;

    img {
      height: 25px;
    }
  }

  .by,
  .address {
    color: #d4d4d4;
    opacity: 0.5;
    text-align: center;
  }
}

.note {
  padding: 10px 25px 25px;
  font-weight: $font-weight-500;
  border-radius: 16px;
  padding-top: 55px;
  text-align: center;

  &.warning {
    position: relative;
    background-color: #ffe0bb;
    color: #292d32;

    &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: 50%;
      width: 30px;
      height: 30px;
      margin: 0 0 0 -15px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url(../assets/warning-2.svg);
    }
  }

  &.note-style-2 {
    text-align: center;
    border-radius: 16px;
    padding: 55px 15px 10px 15px;

    &.warning::before {
      top: 15px;
      left: 50%;
      margin: 0 0 0 -15px;
    }
  }
}

.icon-eye {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/u_eye.svg);
}
.icon-copy {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/copy.svg);
}
.icon-equiv {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/switch-vertical.svg);
}
.icon-mastercard {
  width: 60px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/mastercard.png);
}
.icon-visa {
  width: 60px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/visa.png);
}
.icon-big-area {
  width: 35px;
  height: 35px;
  background-size: 60%;
}
.icon-loading {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/loading-forever.gif);
}
.icon-back-button {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/back-button.svg);

  &.lg {
    width: 30px;
    height: 30px;
  }
}
.icon-warning {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../assets/warning-2.svg);
}

.rc-menu-submenu-popup {
  z-index: 999;
}
.rc-menu {
  border: 0;
  padding: 0;
  box-shadow: none;
  background-color: transparent !important;

  .rc-menu-submenu-arrow {
    display: none;
  }

  .rc-menu-submenu .rc-menu-submenu-title {
    padding: 0;
  }

  li.rc-menu-submenu,
  li:hover {
    background-color: transparent;
  }

  li.rc-menu-submenu-active,
  li.rc-menu-submenu-selected {
    .rc-menu-submenu-title {
      background-color: transparent;
    }
  }

  .rc-menu-submenu-title {
    padding: 0 !important;
  }
}
.rc-menu-submenu:not(li) {
  padding: 0;
  border-radius: 4px;
  background-color: #0f0f0f;
  border: 1px solid rgba(255, 255, 255, 0.1);

  .rc-menu-item {
    cursor: pointer;
    color: $color-white;
    font-weight: $font-weight-500;
  }

  &:hover,
  &.rc-menu-submenu-open,
  &.rc-menu-submenu-selected {
    background-color: #0f0f0f !important;
  }

  .rc-menu-item-active,
  .rc-menu-item-selected {
    background-color: #0f0f0f !important;
  }
}

.ptr {
  background-color: $default-background-color;

  .lds-ellipsis div {
    background: #ffffff;
  }

  .ptr__pull-down--pull-more {
    padding-top: 10px;
    text-align: center;
  }
}

.global-loading-container {
  width: 100%;
  height: 100%;
  background-color: $default-background-color;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin: -15px 0 0 -15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../assets/loading-forever.gif);
  }
}

.tap-effect:active,
.tap-effect:visited {
  user-select: none;
  -webkit-user-select: none;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.custom-radio {
  display: flex;
  align-items: center;

  input[type='radio'] {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    transition: box-shadow 0.3s ease, border 0.3s ease;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &:checked:before {
      background: $color-links-default;
      border: 1px solid $color-links-default;
      content: '\2713';
      font-size: 14px;
      color: black;
      text-align: center;
      line-height: 20px;
    }

    &:not(:checked) {
      border: 1px solid #999;
    }
  }

  label {
    margin-left: 10px;
  }
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  margin-bottom: 15px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    background: transparent;

    &:checked + .checkmark {
      background-color: $color-links-default;
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid $color-links-default;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      border: 1px;
    }
  }

  input:checked + .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
