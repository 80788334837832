$color-white: #ffffff;
$color-black: #000000;

$color-primary: #5e2bc9;
$color-secondary: #f20089;

$color-text-default: #ffffff;
$color-text-primary: #ffffff;

$color-links-default: #f72585;
$color-links-default--hover: #f72585;

$default-background-color: #0f0f0f;

$btn-primary-background-color: #5e2bc9;
$btn-primary-border-color: #5e2bc9;

$btn-secondary-background-color: #ffffff;
$btn-secondary-border-color: #ffffff;

$input-border-color: rgba(255, 255, 255, 0.1);
$input-border-color--active: #dbe2ea;
$input-background-color: rgba(34, 35, 38, 0.1);

$text-muted-color: #aaaaaa;

.color-e5383b {
  color: #e5383b !important;
}
.color-ffffff {
  color: #ffffff !important;
}
.color-0f0f0f {
  color: #0f0f0f !important;
}
.color-d4d4d4 {
  color: #d4d4d4 !important;
}
.color-r255-g255-b255-a50 {
  color: rgba(255, 255, 255, 0.5);
}
.color-5e2bc9 {
  color: #5e2bc9 !important;
}
