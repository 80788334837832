.hs-list-steps {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 25px 0 50px;
    position: relative;
    padding-left: 55px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: attr(data-n);
      position: absolute;
      top: 0;
      left: 0;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      background-color: #4b4b4b;
      border-radius: 100%;
    }

    p {
      margin: 0;
      padding: 0;
      margin-bottom: 35px;
      font-weight: 600;
      text-align: left !important;
    }

    .img-holder {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        display: inline-block;
      }
    }
  }
}
