.referral-content {
  .balloon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #bdbdbd;
    margin: 2rem 2rem;
  }
  .button {
    margin: 3rem;
  }

  .instructions {
    display: block;
    margin: 0 auto;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-left: 25px;
      position: relative;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url(../../assets/check-circle.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
}
