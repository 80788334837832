.tab-wrapper {
  position: relative;

  .slick-dots {
    top: -50px;
    position: absolute;
    bottom: unset;
    background-color: rgba(255, 255, 255, 0.1);
    height: 44px;
    border-radius: 44px;
    display: flex !important;
    align-items: center;
    justify-content: stretch;

    li {
      position: relative;
      height: 44px;
      width: calc(100% / 2) !important;
      margin: 0;
      padding: 0;

      .tab-nav {
        height: 44px;
        position: relative;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.slick-active::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: #4b4b4b;
        width: 100%;
        height: 44px;
        border-radius: 44px;
      }
    }
  }

  .slick-slider {
    position: unset;
  }

  .slick-list {
    margin-top: 80px;
  }
}

ul.stat-wrap {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 15px;

  li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 0;

    &:last-child {
      border-bottom: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }

    &.stat-data p.title {
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 5px;
    }

    &.stat-data p.sub,
    &.stat-platform p.sub {
      font-weight: 500;

      span {
        color: #6c6c6c;
      }
    }

    &.stat-platform {
      display: flex;
      justify-content: space-between;
    }
    &.stat-platform p.title {
      font-weight: 500;
    }
  }
}
