@import '../../styles/base.scss';

.home-container {
  position: relative;
  z-index: 999 !important;

  .home-illus {
    margin-top: 15px;
    height: 400px;
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../assets/illus-1.svg);
  }

  .account-info {
    h1 {
      display: inline-block;
      position: relative;
      font-weight: $font-weight-700;
      margin-bottom: 5px;
    }

    p {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.5);

      span.separator {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 5px;
        border-radius: 100%;
        background-color: #d9d9d9;
        font-weight: $font-weight-500;
      }
    }
  }

  .balance-info {
    h1 {
      font-weight: $font-weight-700;
      margin-bottom: 5px;
    }

    p {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.5);
    }

    .equiv {
      font-weight: $font-weight-600;
    }
  }

  .card-illustration {
    position: relative;

    .card-wrap {
      background-color: $color-black;
      border-radius: 8px;
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url(../../assets/card-theme-1.svg);
      padding: 20px;

      > * {
        height: 33.33%;
      }

      .card-head {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }

      .card-center {
        display: flex;
        align-items: center;
      }

      .card-info {
        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.5);
        }

        h5 {
          margin: 0;
          padding: 0;
          font-weight: $font-weight-700;
        }
      }

      .card-base {
        display: flex;
        align-items: flex-end;

        .wrap {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      @media (max-width: 450px) {
        height: 230px;
      }
    }

    &.loading::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      z-index: 9;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &.loading::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin: -15px 0 0 -15px;
      z-index: 99;
      background-image: url(../../assets/loading-forever.gif);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .tab-wrapper {
    position: relative;

    .slick-dots {
      top: -50px;
      position: absolute;
      bottom: unset;
      background-color: rgba(255, 255, 255, 0.1);
      height: 44px;
      border-radius: 44px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;

      li {
        position: relative;
        height: 44px;
        width: calc(100% / 3);
        margin: 0;
        padding: 0;

        .tab-nav {
          height: 44px;
          position: relative;
          z-index: 99;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.slick-active::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background: #4b4b4b;
          width: 100%;
          height: 44px;
          border-radius: 44px;
        }
      }
    }

    .slick-slider {
      position: unset;
    }

    .slick-list {
      margin-top: 80px;
    }
  }

  ul.card-details {
    list-style: none;
    margin: 0;
    padding: 15px 0 0 0;

    li {
      padding: 10px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        color: rgba(255, 255, 255, 0.6);
        font-size: $font-size-medium;
      }

      .value {
        font-weight: $font-weight-500;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  ul.card-transactions {
    list-style: none;
    margin: 0;
    padding: 15px 0 0 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      padding-left: 50px;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 36px;
        height: 36px;
        margin: -18px 0 0 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .left {
        max-width: 70%;
      }
      .right {
        display: flex;
        align-items: center;
      }

      span.currency {
        font-size: 12px;
        color: #6c6c6c;
      }
      span.amount {
        font-size: 18px;
        margin-left: 5px;
        font-weight: $font-weight-700;
      }

      &.tx-in {
        &::before {
          background-image: url(../../assets/card-tx-in.svg);
        }

        .amount {
          color: #2b9348;
        }
      }
      &.tx-out {
        &::before {
          background-image: url(../../assets/card-tx-out.svg);
        }

        .amount {
          color: #e5383b;
        }
      }
      &.tx-pending {
        &::before {
          background-image: url(../../assets/card-txn-pending.svg);
        }

        .amount {
          color: #ffffff;
        }
      }
      &.tx-reverserd-refunded {
        &::before {
          background-image: url(../../assets/card-txn-reversed.svg);
        }

        .amount {
          color: #2b9348;
        }
      }
      &.tx-declined {
        &::before {
          background-image: url(../../assets/card-txn-declined.svg);
        }

        .amount {
          color: #ffffff;
        }
      }

      .label {
        font-weight: $font-weight-500;
      }
      .value {
        color: rgba(255, 255, 255, 0.6);
        font-size: $font-size-medium;
      }
      .info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $font-size-medium;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .card-decline-info {
    border: 1px solid #272727;
    background-color: #000000;
    border-radius: 6px;
    padding: 15px;
    display: flex;
    align-items: start;
    justify-content: stretch;
    gap: 10px;

    p {
      margin: 0;
      padding: 0;
    }

    a {
      cursor: pointer;
    }
  }
}
