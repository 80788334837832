@import '../../styles/base.scss';

.auth-container {
  .hero-container {
    margin: 0 auto;
    max-width: 400px;

    img {
      width: 100%;
    }
  }

  .hero-caption {
    p {
      text-align: center;
    }
  }

  .app-homescreen-view {
    position: relative;

    .app-tab-navs {
      position: relative;
      height: 50px;
      border-radius: 44px;
      background-color: rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: stretch;

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 30px;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -0.5px;
        background-color: rgba(255, 255, 255, 0.1);
      }

      a {
        cursor: pointer;
        display: flex;
        height: 100%;
        width: 50%;
        align-items: center;
        justify-content: center;

        span {
          color: $color-white;
          margin-left: 10px;
        }
      }
    }

    .slick-dots {
      top: 50px;
      position: absolute;
      bottom: unset;
      background-color: rgba(255, 255, 255, 0.1);
      height: 44px;
      border-radius: 44px;
      display: flex !important;
      align-items: center;
      justify-content: stretch;

      li {
        position: relative;
        height: 44px;
        width: calc(100% / 2);
        margin: 0;
        padding: 0;

        .tab-nav {
          height: 44px;
          position: relative;
          z-index: 99;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: $color-white;
            margin-left: 10px;
          }
        }

        &.slick-active::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background: #4b4b4b;
          width: 100%;
          height: 44px;
          border-radius: 44px;
        }
      }
    }

    .slick-slider {
      position: unset;
    }

    .app-tab {
      margin-top: 35px;
    }
  }
}
